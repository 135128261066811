<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("itemstock.title") }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-form @submit.prevent="insertItemStock">
          <b-row>
            <b-col lg="4" md="4" sm="12">
              <b-form-group label="Item Title">
                <b-form-input v-model="itemstock.title" :placeholder="$t('itemstock.itemname')" required />
              </b-form-group>
            </b-col>
            <b-col lg="4" md="4" sm="12">
              <b-form-group :label="$t('itemstock.stock')">
                <b-form-input type="number" v-model="itemstock.stock" :placeholder="$t('itemstock.stock')" required />
              </b-form-group>
            </b-col>

            <b-col lg="4" md="4" sm="12">
              <b-form-group :label="$t('itemstock.itemimage')">
                <b-form-file v-model="imagen" :placeholder="$t('itemstock.itemimage')"
                  drop-placeholder="Drop file here..." required @input="readURL" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Item Details">
                <quill-editor required style="min-height:150px;" v-model="itemstock.description"
                  :placeholder="$t('itemstock.description')" :options="editorOption">
                  <div id="toolbar" slot="toolbar">
                    <button class="ql-bold">Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      underline
                    </button>
                    <span class="ql-formats">
                      <button class="ql-align" value=""></button>
                      <button class="ql-align" value="center"></button>
                      <button class="ql-align" value="right"></button>
                      <button class="ql-align" value="justify"></button>
                    </span>
                    <button class="ql-header" value="1">
                      H1
                    </button>
                    <button class="ql-header" value="2">
                      H2
                    </button>
                    <button class="ql-list" value="ordered" />
                    <button class="ql-list" value="bullet" />
                    <button class="ql-indent" value="-1" />
                    <button class="ql-indent" value="+1" />
                    <button class="ql-emoji">
                      emoji
                    </button>
                  </div>
                </quill-editor>
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group :label="$t('itemstock.price')">
                <b-form-input type="number" v-model="itemstock.price" :placeholder="$t('itemstock.price')" required />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group :label="$t('itemstock.selecttype')">
                <b-form-select v-model="itemstock.type.value" :options="option" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group label="Select a Coin">
                <b-form-select v-model="itemstock.typeCoin.value" :options="optionsCoin" />
              </b-form-group>
            </b-col>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
              <div class="flex flex-row gap-1 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-if="saving">
                  <path fill="currentColor"
                    d="M12 2A10 10 0 1 0 22 12A10 10 0 0 0 12 2Zm0 18a8 8 0 1 1 8-8A8 8 0 0 1 12 20Z" opacity=".5" />
                  <path fill="currentColor" d="M20 12h2A10 10 0 0 0 12 2V4A8 8 0 0 1 20 12Z">
                    <animateTransform attributeName="transform" dur="1s" from="0 12 12" repeatCount="indefinite"
                      to="360 12 12" type="rotate" />
                  </path>
                </svg>
                <div class="text-white font-bold text-base">Save</div>
              </div>
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
              {{ $t('itemstock.reset') }}
            </b-button>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BCardHeader, BCardTitle, BCardBody, BFormFile,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SellerService from '@/services/SellerService'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import "quill-emoji/dist/quill-emoji.css";
import Quill from 'quill'
import * as Emoji from "quill-emoji";
Quill.register("modules/emoji", Emoji);
import { quillEditor } from 'vue-quill-editor'
import flatPickr from 'vue-flatpickr-component'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton, BCard, BCardHeader, BCardTitle, BCardBody, BFormFile, vSelect,
    quillEditor, BFormSelect, flatPickr
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      itemstock: {
        title: "",
        description: "",
        price: "",
        stock: "",
        type: {
          value: 0
        },
        typeCoin: {
          value: 1
        },
      },
      editorOption: {
        modules: {
          toolbar: '#toolbar',
          "emoji-toolbar": true,
          "emoji-textarea": true,
          "emoji-shortname": true,
        }
      },
      imagen: null,
      imagenFile: null,
      option: [],
      dialogHidden: true,
      optionsCoin: [{ text: 'USDC', value: 1 },],
      saving: false
    }
  },
  mounted() {
    this.getTypes()
  },
  methods: {
    toogleDialogEmoji() {
      this.dialogHidden = !this.dialogHidden;
    },
    onSelectEmoji(dataEmoji) {
      this.moption.description += dataEmoji.data;
      this.toogleDialogEmoji();
    },

    getTypes: async function () {
      const b = (await SellerService.getTypesItem(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.option = b
    },

    readURL: function (input) {
      const vm = this;
      if (input) {
        var reader = new FileReader();
        reader.readAsDataURL(input);
        reader.onload = function (e) {
          vm.imagen = e.target.result;
          vm.imagenFile = input;
        };
      }
    },
    insertItemStock: async function () {
      this.saving = true
      const insertar = (await SellerService.insertItemStock(JSON.parse(localStorage.getItem("auth")).discordId,
        this.itemstock, this.imagenFile))
      this.saving = false
      this.$swal({
        title: "",
        text: "Item Saved Successfull",
        icon: "success",
        allowOutsideClick: false,
        showClass: {
          popup: "animate__animated animate__fadeIn",
        },
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$router.go(0)
        }
      });
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.ql-container,
.ql-editor {
  min-height: inherit;
}
</style>